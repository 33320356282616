import { gql } from '@apollo/client';
import {
  REGION_FRAGMENT,
  DEDICATED_VPC_FRAGMENT,
  VPC_PEERING_FRAGMENT,
} from '../fragments';

export const REALTIME_VPC_STATUS = gql`
  ${DEDICATED_VPC_FRAGMENT}
  ${VPC_PEERING_FRAGMENT}
  subscription watchDedicatedVPCs {
    dedicated_vpc(
      where: { status: { _neq: destroyed } }
      order_by: [{ created_at: desc }]
    ) {
      ...DedicateVPCFields
      vpc_peerings {
        ...VPCPeeringFields
      }
      tenants_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_AWS_ID = gql`
  query getAWSId {
    cloud_metadata {
      aws_account_id
    }
  }
`;

export const GET_AWS_REGIONS = gql`
  ${REGION_FRAGMENT}
  query getAWSRegions {
    region_v2(where: { is_active: { _eq: true }, cloud: { _eq: "aws" } }) {
      region {
        ...RegionFields
      }
    }
  }
`;

export const GET_USER_VPC_LIMIT = gql`
  query getUserVPCLimit($userId: uuid) {
    user_vpc_policy(where: { user: { _eq: $userId } }) {
      vpc_limit
      plan_name
    }
  }
`;

export const GET_USER_VPC_COUNT = gql`
  query getVPCCount($userId: uuid) {
    dedicated_vpc_aggregate(where: { owner_id: { _eq: $userId } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_AWS_VPC_PEERING_DETAILS = gql`
  query getAwsVpcPeeringDetails($id: uuid, $dedicatedVpcId: uuid) {
    vpc_peering(
      where: { id: { _eq: $id }, dedicated_vpc_id: { _eq: $dedicatedVpcId } }
    ) {
      id
      aws_customer_account_id
      aws_customer_vpc_additional_cidrs
      aws_customer_vpc_cidr
      aws_customer_vpc_id
      aws_customer_vpc_region
      dedicated_vpc_id
      name
    }
  }
`;

export const RESEND_VPC_INVITE = gql`
  mutation resendInvitation($args: ResendInvitationRequest!) {
    resendInvite(args: $args) {
      id
      collaborator_email
    }
  }
`;

export const INVITE_VPC_COLLABORATOR = gql`
  mutation inviteVPCCollaborator(
    $invitee_email: String!
    $access_level: VPCCollaboratorAccessLevel!
    $vpc_id: uuid!
  ) {
    inviteVPCCollaborator(
      vpc_id: $vpc_id
      invitee_email: $invitee_email
      access_level: $access_level
    ) {
      message
      status
    }
  }
`;

export const FETCH_VPC_INVITATIONS = gql`
  query fetchVPCPendingInvitations(
    $collaborator_email: citext!
    $status: String!
  ) {
    vpc_collaborator_invitations(
      where: {
        collaborator_email: { _eq: $collaborator_email }
        status: { _eq: $status }
      }
    ) {
      accepted_at
      collaborator_email
      dedicated_vpc {
        id
        name
        owner_id
        region
        region_info {
          cloud
          name
        }
      }
      id
      invited_by
      privilege_slug
      status
      vpc_id
      invited_by_user {
        email
      }
      vpc_name
      invited_by_email
    }
  }
`;

export const ACCEPT_VPC_INVITE = gql`
  mutation acceptVPCInvite($invitation_id: uuid!) {
    acceptVPCCollaboratorInvitation(invitation_id: $invitation_id) {
      message
      status
    }
  }
`;

export const DECLINE_VPC_INVITE = gql`
  mutation declineVPCInvite($invitation_id: uuid!) {
    declineVPCCollaboratorInvitation(invitation_id: $invitation_id) {
      message
      status
    }
  }
`;

export const REVOKE_VPC_INVITE = gql`
  mutation revokeVPCInvite($invitation_id: uuid!) {
    revokeVPCCollaboratorInvitation(invitation_id: $invitation_id) {
      message
      status
    }
  }
`;

export const DELETE_VPC_COLLABORATOR = gql`
  mutation deleteVPCCollaborator(
    $collaborator_id: uuid!
    $vpc_id: uuid!
    $remove_collaborator_from_projects_list: [uuid!]!
  ) {
    removeVPCCollaborator(
      collaborator_id: $collaborator_id
      vpc_id: $vpc_id
      remove_collaborator_from_projects_list: $remove_collaborator_from_projects_list
    ) {
      message
      status
    }
  }
`;

export const GET_VPC_OWNER = gql`
  query getVPCOwner($domain: String!) {
    dedicated_vpc_owner(where: { domain: { _eq: $domain } }) {
      domain
      vpc_owner_email
    }
  }
`;
